$(window).scroll(function(){
  var H=0,
   _st=$(window).scrollTop(),
    _side_h=$('.scrolltop_slide_container').outerHeight(),
    kk = $(window).outerHeight()-_side_h-0
    // console.log('scrollTop:'+K);
	  // console.log('螢幕高:'+kk);
    let _h_half=$(window).outerHeight(true)/2,
    _side_h_helf=_side_h/2
    
    if(_st>0){
        $(".scrolltop_slide_container").stop(true,false).animate({
          top:_st+_h_half-_side_h_helf-50
        },500);
    }
})
