    $(function() {
      $('aside h2').on('click', function() {
        console.log('zz')
        if ($(this).hasClass('open')) {
          $(this).removeClass('open')
          $('.fitler_container').hide();
          $(this).find('.arrow i').removeClass('rotate')
        } else {
          $(this).addClass('open')
          $('.fitler_container').show();
          $(this).find('.arrow i').addClass('rotate')
        }
      })
    })
    $(window).on('resize', function() {
      // console.log($(window).outerWidth(true))
      if ($(window).outerWidth(true) > 767) {
        $('.fitler_container').show();
      }
    })