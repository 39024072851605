$(function(){
  var $tab=$('.tab'),
      $tab_btn=$tab.find('li'),
      $tab_conbox=$('.tab-conbox'),
      $tab_con=$tab_conbox.find('section')

  $tab_con.css('display','none').eq(0).css('display','block');
  $tab_btn.click(function(e){
    e.preventDefault();
    $tab_btn.find('a').removeClass();
    $(this).find('a').addClass('selected');
    var _index=$(this).index();
    $tab_con.css('display','none').eq(_index).fadeIn();
    // console.log('index:'+_index); 
  })



  // var $win=$(window),
  //     _nav_h=$('.nav-box').outerHeight(true),
  //     $tab=$('.tab'),
  //     _tab_pos=$tab.offset()

  // $('.tobuy').click(function(){
  //   $win.scrollTop(_tab_pos.top);
  //   $tab_btn.find('a').removeClass();
  //   $tab_btn.eq(2).find('a').addClass('selected');
  //   $tab_con.css('display','none').eq(2).fadeIn();
  // })
})

